import { default as dashboardQ8muXvTWcRMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/admin/dashboard.vue?macro=true";
import { default as loginJChqAMhR4eMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/admin/login.vue?macro=true";
import { default as choose_45categoryqKMBIxpMeuMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/choose-category.vue?macro=true";
import { default as defaultTrS1MLP6bxMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/default.vue?macro=true";
import { default as indexzBtXuL1pB0Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/index.vue?macro=true";
import { default as claim_45prize1adc9TYRkqMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/claim-prize.vue?macro=true";
import { default as indexNBG9wfJEDQMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/index.vue?macro=true";
import { default as max_45playedYf56HYQiF9Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/max-played.vue?macro=true";
import { default as no_45win6O6uNngcrRMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/no-win.vue?macro=true";
import { default as prize_45claimedE3TcAJiAjHMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/prize-claimed.vue?macro=true";
import { default as winDeL3TTiZsoMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/win.vue?macro=true";
import { default as wrong_45answerSYq5c04whyMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/wrong-answer.vue?macro=true";
import { default as answer_45correctaNqbhqhyX1Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/answer-correct.vue?macro=true";
import { default as beeWWgyBRpzMMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/be.vue?macro=true";
import { default as congratulationsQ8YU2EbnFTMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/congratulations.vue?macro=true";
import { default as formt8DEKuefBLMeta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/form.vue?macro=true";
import { default as loginLkCRUSuMF6Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/login.vue?macro=true";
import { default as question6xggox0fm1Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/question.vue?macro=true";
import { default as thankskZNeeYi1R4Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/thanks.vue?macro=true";
import { default as wheelGw0OKhD124Meta } from "/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/wheel.vue?macro=true";
import { default as choose_45categoryjN3onVU1y5Meta } from "~/pages/choose-category.vue?macro=true";
import { default as wrong_45answer9GjULBXgXmMeta } from "~/pages/quiz/wrong-answer.vue?macro=true";
export default [
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardQ8muXvTWcRMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/admin/dashboard.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: loginJChqAMhR4eMeta || {},
    alias: ["/login"],
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/admin/login.vue")
  },
  {
    name: "choose-category",
    path: "/choose-category",
    meta: choose_45categoryqKMBIxpMeuMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/choose-category.vue")
  },
  {
    name: "default",
    path: "/default",
    meta: defaultTrS1MLP6bxMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/default.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexzBtXuL1pB0Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/index.vue")
  },
  {
    name: "quiz-claim-prize",
    path: "/quiz/claim-prize",
    meta: claim_45prize1adc9TYRkqMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/claim-prize.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: indexNBG9wfJEDQMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/index.vue")
  },
  {
    name: "quiz-max-played",
    path: "/quiz/max-played",
    meta: max_45playedYf56HYQiF9Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/max-played.vue")
  },
  {
    name: "quiz-no-win",
    path: "/quiz/no-win",
    meta: no_45win6O6uNngcrRMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/no-win.vue")
  },
  {
    name: "quiz-prize-claimed",
    path: "/quiz/prize-claimed",
    meta: prize_45claimedE3TcAJiAjHMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/prize-claimed.vue")
  },
  {
    name: "quiz-win",
    path: "/quiz/win",
    meta: winDeL3TTiZsoMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/win.vue")
  },
  {
    name: "quiz-wrong-answer",
    path: "/quiz/wrong-answer",
    meta: wrong_45answerSYq5c04whyMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/quiz/wrong-answer.vue")
  },
  {
    name: "static-answer-correct",
    path: "/static/answer-correct",
    meta: answer_45correctaNqbhqhyX1Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/answer-correct.vue")
  },
  {
    name: "static-be",
    path: "/static/be",
    meta: beeWWgyBRpzMMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/be.vue")
  },
  {
    name: "static-congratulations",
    path: "/static/congratulations",
    meta: congratulationsQ8YU2EbnFTMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/congratulations.vue")
  },
  {
    name: "static-form",
    path: "/static/form",
    meta: formt8DEKuefBLMeta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/form.vue")
  },
  {
    name: "static-login",
    path: "/static/login",
    meta: loginLkCRUSuMF6Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/login.vue")
  },
  {
    name: "static-question",
    path: "/static/question",
    meta: question6xggox0fm1Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/question.vue")
  },
  {
    name: "static-thanks",
    path: "/static/thanks",
    meta: thankskZNeeYi1R4Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/thanks.vue")
  },
  {
    name: "static-wheel",
    path: "/static/wheel",
    meta: wheelGw0OKhD124Meta || {},
    component: () => import("/home/forge/staging.quiz.zuyderland.nl/releases/20250125071602/pages/static/wheel.vue")
  },
  {
    name: "choose-category-nl",
    path: "/kies-categorie",
    meta: choose_45categoryjN3onVU1y5Meta || {},
    component: () => import("~/pages/choose-category.vue")
  },
  {
    name: "wrong-answer-nl",
    path: "/quiz/fout-antwoord",
    meta: wrong_45answer9GjULBXgXmMeta || {},
    component: () => import("~/pages/quiz/wrong-answer.vue")
  }
]